/*
 * Application global variables.
 */
@use '@angular/material' as mat;

// Angular Material custom theme
// See https://material.angular.io/guide/theming for more details.
//
// You can also read https://medium.com/@tomastrajan/the-complete-guide-to-angular-material-themes-4d165a9d24d1
// for more insight about Angular Material theming.


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.

//you can generate theme palette here: http://mcg.mbitson.com/
// changed contrast colors for 500, 600 and 700
$sc-orange: (
  50: #fff3e0,
  100: #ffe0b2,
  200: #ffcc80,
  300: #ffb74d,
  400: #ffa726,
  500: #ff9800,
  600: #fb8c00,
  700: #f57c00,
  800: #ef6c00,
  900: #e65100,
  A100: #ffd180,
  A200: #ffab40,
  A400: #ff9100,
  A700: #ff6d00,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: black
  )
);

// Material design icons font path
$material-design-icons-font-directory-path: "~material-design-icons-iconfont/dist/fonts/";

$primary: mat.define-palette(mat.$blue-palette);
$accent: mat.define-palette(mat.$grey-palette);
$grey: mat.define-palette(mat.$grey-palette);
$warn: mat.define-palette(mat.$red-palette);
// $success: mat-palette($mat-green);

$danger: mat.get-color-from-palette($warn, "default"); // #f4516c;
// $success: mat-color($success, "default"); //#34bfa3;

$config: mat.define-legacy-typography-config();
