/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

@import "assets/custom/css/variables.scss";

// Theme customization
@import "theme/theme";
@import "theme/theme-functions";

// 3rd party libraries
@import "/node_modules/material-design-icons-iconfont/src/material-design-icons.scss";

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//@include angular-material-theme($theme);

$pdfViewports: (
  0px: (
    pdf-height: 100%,
    total-height: 100%
  ),
  1280px: (
    pdf-height: calc(100vh - (calc(53px + 52px) + 48px)),
    total-height: calc(100vh - (calc(53px + 52px) + 48px))
  )
);

body {
  height: 100vh;
  width: 100vw;
  margin: 0;
}
// import custom componenet themes
@import "./assets/custom/css/custom";

.theme-default {
  // Call the main material theme mixin to create all the default theming styles.
  // Call the custom component mixin to create all the custom component theming styles
  // @include custom-components-theme($theme-default);
}

//
// Theme 1
//

// auto light/dark mode — depending on system preferences
// .theme-1:not(.light):not(.dark) {
//   @include custom-components-theme($theme-client-1);

//   @media (prefers-color-scheme: light) {
//     @include custom-components-theme($theme-client-1);
//   }

//   @media (prefers-color-scheme: dark) {
//     @include custom-components-theme($theme-client-1-dark);
//   }

//   @media (prefers-color-scheme: no-preference) {
//     @include custom-components-theme($theme-client-1);
//   }
// }

// // force light mode
// .theme-1.light {
//   @include custom-components-theme($theme-client-1);
// }

// // force dark mode
// .theme-1.dark {
//   @include custom-components-theme($theme-client-1-dark);
// }
